import { FC, HTMLProps } from "react";
import cn from "classnames";
import { Appearance, AppearanceBackground } from "@/components/sections/types";

import styles from "./index.module.scss";

export interface AppearanceWrapperProps extends HTMLProps<HTMLDivElement> {
  appearance: Appearance;
}

export const AppearanceWrapper: FC<AppearanceWrapperProps> = ({ children, className, appearance, ...rest }) => {
  const backgroundStyles: Record<AppearanceBackground, string> = {
    Bg: styles.backgroundBg,
    BgLight: styles.backgroundBgLight,
    White: styles.backgroundWhite,
  };

  return (
    <div
      data-testid="AppearanceWrapper"
      className={cn(
        styles.container,
        backgroundStyles[appearance?.background],
        appearance?.paddingBottom && styles.paddingBottom,
        appearance?.paddingTop && styles.paddingTop,
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
