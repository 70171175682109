import { FC } from "react";
import { Cross, Doc } from "@mafin/icons";
import { formatBytes } from "@/utils/files";

import styles from "./index.module.scss";

interface FileProps {
  name: string;
  size: number;
  errorMessage?: string | false;
  children: string;

  onDeleteButtonClick(): void;
}

export const File: FC<FileProps> = ({ onDeleteButtonClick, name, size, errorMessage }) => {
  return (
    <div className={styles.file}>
      <div className={styles.icon}>
        <Doc />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.information}>
          <div className={styles.fileNameWrapper}>
            <p className={styles.fileName}>{name}</p>
          </div>
          {errorMessage ? (
            <p className={styles.errorMessage}>{errorMessage}</p>
          ) : (
            <p className={styles.fileSize}>{formatBytes(size)}</p>
          )}
        </div>
        <div className={styles.delete} onClick={onDeleteButtonClick}>
          <Cross className={styles.deleteIcon} />
        </div>
      </div>
    </div>
  );
};
