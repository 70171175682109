import { FC } from "react";
import cn from "classnames";

import { Title as ITitle } from "../../sections/types";

import styles from "./index.module.scss";

interface TitleProps extends ITitle {
  className?: string;
}

export const Title: FC<TitleProps> = ({ text, type, className }) => {
  if (type === "h1") {
    return <h1 className={cn(styles.title, styles.h1, className)}>{text}</h1>;
  } else if (type === "h2") {
    return <h2 className={cn(styles.title, styles.h2, className)}>{text}</h2>;
  } else if (type === "h3") {
    return <h3 className={cn(styles.title, styles.h3, className)}>{text}</h3>;
  } else if (type === "h4") {
    return <h4 className={cn(styles.title, styles.h4, className)}>{text}</h4>;
  } else if (type === "h5") {
    return <h5 className={cn(styles.title, styles.h5, className)}>{text}</h5>;
  }

  return <h2 className={cn(styles.title, styles.h2, className)}>{text}</h2>;
};
