import { FC } from "react";
import DOMPurify from "isomorphic-dompurify";
import cn from "classnames";

import styles from "./index.module.scss";

interface HTMLFromRichTextEditorProps {
  htmlString: string;
  sanitize?: boolean;
  className?: string;
}

export const HTMLFromRichTextEditor: FC<HTMLFromRichTextEditorProps> = ({
  htmlString,
  sanitize = false,
  className,
}) => {
  const html = sanitize ? DOMPurify.sanitize(htmlString, { ADD_ATTR: ["target"] }) : htmlString;

  return <div className={cn(styles.container, className)} dangerouslySetInnerHTML={{ __html: html }} />;
};
