export const setGrzToLocalStorage = (text: string) => {
  const scenario = JSON.stringify([
    {
      single: true,
      condition: {
        when: {
          type: { equal: "QUESTION" },
          camunda_task_id: {
            some: ["Task_GetPlate", "Task_Osago_GetPlate"],
          },
        },
        then: {
          send: {
            object: {
              type: "TEXT",
              data: null,
              text,
              media: null,
            },
          },
        },
      },
    },
  ]);

  window.localStorage.setItem("DIALOG_SCENARIO", scenario);
};

export const getGrzWithSpaces = (grz: string) =>
  `${grz.slice(0, 1)} ${grz.slice(1, 4)} ${grz.slice(4, 6)} ${grz.slice(6)}`;

export const isValidGrz = (grz: string) => {
  const plateRegex = /[АВЕКМНОРСТУХ] \d{3} [АВЕКМНОРСТУХ]{2} ([0-9]{2,3})/;

  return plateRegex.test(grz);
};
