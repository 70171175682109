import React from "react";
import Image from "next/image";
import cn from "classnames";
import { Appearance, StrapiData, Title as ITitle, Image as IImage, Link as ILink } from "@/components/sections/types";
import { AppearanceWrapper } from "@/components/shared/appearance-wrapper";
import { Title } from "@/components/shared/title";
import { strapiLinkComponentToAnchorProps } from "@/utils/links";
import { getStrapiMedia } from "@/utils/media";
import {
  getNextImageSize,
  MAX_MOBILE_MINI_WIDTH,
  MAX_MOBILE_WIDTH,
  MAX_LAPTOP_WIDTH,
} from "@/utils/next-image-helpers";

import styles from "./index.module.scss";

export interface CrossProductProps {
  title: ITitle;
  productCards: Array<{
    id: number;
    title: string;
    image: IImage;
    link: ILink;
  }>;
  appearance: Appearance;
}

export const CrossProduct: React.FC<StrapiData<CrossProductProps>> = ({
  data: { title, productCards = [], appearance },
}) => {
  const NUMBER_OF_COLUMNS = 3;
  const NUMBER_OF_LARGE_CARDS_ON_ROW = 2;

  const isNumberOfCardsEven = !(productCards.length % 2);
  const numberOfLargeCards =
    ((NUMBER_OF_COLUMNS - (productCards.length % NUMBER_OF_COLUMNS)) % NUMBER_OF_COLUMNS) *
    NUMBER_OF_LARGE_CARDS_ON_ROW;

  const getNextImageSizes = () =>
    getNextImageSize(287, MAX_MOBILE_MINI_WIDTH) +
    getNextImageSize(343, MAX_MOBILE_WIDTH) +
    getNextImageSize(480, MAX_LAPTOP_WIDTH) +
    getNextImageSize(557);

  return (
    <AppearanceWrapper appearance={appearance}>
      <div className={styles.sectionContainer}>
        <Title className={styles.title} {...title} />
        <div
          className={cn(styles.cardsContainer, isNumberOfCardsEven && styles.cardsContainer_evenCardCount)}
          data-testid="cardsContainer"
        >
          {productCards.map((card, index) => (
            <a
              key={card.id}
              className={cn(styles.cardLink, index < numberOfLargeCards && styles.cardLink_large)}
              {...strapiLinkComponentToAnchorProps(card.link)}
            >
              <Image
                className={styles.image}
                src={getStrapiMedia(card.image.url)}
                alt={card.title}
                sizes={getNextImageSizes()}
                objectFit="cover"
                layout="fill"
                quality={70}
                priority
              />
              <div className={styles.text}>{card.title}</div>
            </a>
          ))}
        </div>
      </div>
    </AppearanceWrapper>
  );
};
