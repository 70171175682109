import { FC } from "react";
import cn from "classnames";
import { Arrow } from "@mafin/icons";
import { Typography } from "@mafin/ui-kit";

import styles from "./index.module.scss";

interface DisclosureButtonProps {
  isOpen: boolean;
  onClick(): void;
  className?: string;
  text?: {
    opened: string;
    closed: string;
  };
}

export const DisclosureButton: FC<DisclosureButtonProps> = ({ isOpen, onClick, className, text }) => {
  const renderButton = (className?: string, onClick?: () => void) => (
    <div className={cn(styles.button, isOpen && styles.open, className)} onClick={onClick}>
      <Arrow direction="down" className={styles.arrow} color={`var(--${isOpen ? "corporate" : "main"})`} />
    </div>
  );

  return text ? (
    <div className={cn(styles.container, className)} onClick={onClick}>
      <Typography variant="h5" color={`var(--${isOpen ? "corporate" : "main"})`} className={styles.text}>
        {isOpen ? text.opened : text.closed}
      </Typography>
      {renderButton()}
    </div>
  ) : (
    renderButton(className, onClick)
  );
};
