import React, { forwardRef } from "react";
import classname from "classnames";

import styles from "./index.module.scss";

type ButtonLinkProps = React.HTMLProps<HTMLAnchorElement> & {
  children: string;
  variant?: "primary" | "secondary";
};

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ children, variant = "primary", className, ...rest }, ref) => {
    return (
      <div
        className={classname(styles.wrapper, { [styles.button_link__secondary]: variant === "secondary" }, className)}
      >
        <a ref={ref} {...rest} className={styles.button_link}>
          <p>{children}</p>
        </a>
      </div>
    );
  }
);
