import { Control, Controller, useController, UseFormTrigger } from "react-hook-form";
import { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from "react";
import { Button } from "@mafin/ui-kit";
import { Clip, Question } from "@mafin/icons";
import { File } from "@/components/shared/file-input/File";
import { Typography } from "@/components/shared/typography";
import { formatBytes } from "@/utils/files";
import { IFormInput } from "@/components/sections/feedback/components/form";

import styles from "./index.module.scss";

interface FileInputProps {
  control: Control<IFormInput, Object>;
  accept?: string;
  maxFilesSize?: number;
  name: "files";
  trigger: UseFormTrigger<IFormInput>;
}

export const FileInput: FC<FileInputProps> = ({ control, maxFilesSize, name, accept, trigger }) => {
  const [files, setFiles] = useState<File[]>([]);
  const size = files ? Array.from(files).reduce((acc, i) => acc + i.size, 0) : 0;
  const [value, setValue] = useState("");
  const invisibleInputRef = useRef<HTMLInputElement>(null);
  const inputAcceptArray = accept?.split(",") || [];
  const inputAccept = inputAcceptArray.map((i) => `.${i}`).join(",");

  const getErrorMessage = () => `Общий объем превысил ${formatBytes(maxFilesSize as number)}`;

  const validateSize = () => {
    return !(maxFilesSize ? size > maxFilesSize : false);
  };

  const error = validateSize() ? undefined : getErrorMessage();

  const { field } = useController({
    control,
    name: name as never,
    rules: { validate: () => validateSize() || getErrorMessage() },
  });

  const handleSelectFilesButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    invisibleInputRef.current?.click();
  };

  const handleDeleteFile = (index: number) => () => {
    setFiles((old) => {
      const draft = [...old];

      draft.splice(index, 1);
      field.onChange(draft);

      return draft;
    });
  };

  const handleInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (e.target.files) {
      const uniqArray = [...files, ...Array.from(e.target.files)].filter(
        (value, index, self) => index === self.findIndex((t) => t.name === value.name && t.size === value.size)
      );

      setFiles(uniqArray);
      field.onChange(uniqArray);
    }
  };

  useEffect(() => {
    trigger(name as never);
  }, [files, name, trigger]);

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div className={styles.fileInput}>
          <div className={styles.inputWrapper}>
            <input
              type="file"
              multiple
              accept={inputAccept}
              ref={invisibleInputRef}
              value={value}
              className={styles.input}
              onChange={handleInputChanged}
            />
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.button}
                icon={<Clip size="small" />}
                onClick={handleSelectFilesButtonClick}
                variant="secondary"
                disabled={!!error}
              >
                Загрузить файлы
              </Button>
              <div className={styles.acceptWrapper}>
                <Question />
                <div>
                  {!error && maxFilesSize && (
                    <Typography type="littleText">{`${formatBytes(size)}/${formatBytes(maxFilesSize)}`}</Typography>
                  )}
                  {accept && (
                    <Typography className={styles.accept} color="additional" type="littleText">
                      {accept.replaceAll(",", ", ")}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            {error && (
              <Typography className={styles.errorMessage} color="error" type="littleText">
                {error}
              </Typography>
            )}
          </div>

          {!!files.length && (
            <div className={styles.filesList}>
              {Array.from(files).map((i, index) => (
                <File
                  key={i.name}
                  name={i.name}
                  size={i.size}
                  errorMessage={!inputAcceptArray.includes(i.name.split(".").pop() || "") && "Недопустимый формат"}
                  onDeleteButtonClick={handleDeleteFile(index)}
                >
                  {i.name}
                </File>
              ))}
            </div>
          )}
        </div>
      )}
    />
  );
};
