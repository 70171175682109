import { memo, useEffect, useMemo, useRef } from "react";
import { Control, Controller, useFormState, UseFormTrigger } from "react-hook-form";
import { Checkbox, Dropdown, Error, Input, Textarea } from "@mafin/ui-kit";
import { Divider } from "@/components/shared/divider";
import { FileInput } from "@/components/shared/file-input";
import { EMAIL_REG_EXP } from "@/utils/constants/regular-expressions";
import { HTMLFromRichTextEditor } from "@/components/shared/html-from-rich-text-editor";
import * as React from "react";
import { useAuth } from "@mafin/utilities";

import styles from "./index.module.scss";

const CATEGORIES = [
  { id: "review", name: "Отзыв" },
  { id: "problem", name: "Техническая проблема" },
  { id: "buySell", name: "Купить/Продлить" },
  { id: "question", name: "Вопрос по полису" },
];

export type FeedbackModalType = "buy" | "review";

export interface IFormInput {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  category: { id: string; name: string };
  text: string;
  files: File[];
  agreement: boolean;
}

interface FormProps {
  enableFiles?: boolean;
  agreementText?: string;
  type: FeedbackModalType;
  control: Control<IFormInput, Object>;
  trigger: UseFormTrigger<IFormInput>;
}

export const Form = memo<FormProps>(({ enableFiles, agreementText, type, control, trigger }) => {
  const agreementRef = useRef<HTMLDivElement>(null);
  const formState = useFormState({ control });
  const { errors } = formState;
  const { user } = useAuth();

  const categoryDefaultValues = useMemo(() => {
    if (type === "buy") {
      return CATEGORIES[2];
    }

    return CATEGORIES[0];
  }, [type]);

  // Скролим вниз если не приняли условия пользовательского соглашения
  useEffect(() => {
    if (Object.keys(formState.errors).length === 1 && formState.errors.agreement) {
      agreementRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [formState]);

  return (
    <form>
      <div className={styles.textFieldsGrid}>
        {!user?.profile?.phone && !user?.profile?.firstName && (
          <div className={styles.userInfoGrid}>
            <Controller
              name="lastName"
              rules={{
                required: { value: true, message: "Фамилия указана некорректно" },
                pattern: { value: /^(|[А-ЯЁа-яё -]{1,38})[А-ЯЁа-яё]$/, message: "Фамилия указана некорректно" },
              }}
              control={control}
              defaultValue=""
              render={({ field }) => <Input error={errors.lastName?.message} label="Фамилия" {...field} />}
            />
            <Controller
              name="firstName"
              rules={{
                required: { value: true, message: "Имя указано некорректно" },
                pattern: { value: /^(|[А-ЯЁа-яё -]{1,38})[А-ЯЁа-яё]$/, message: "Имя указано некорректно" },
              }}
              control={control}
              defaultValue=""
              render={({ field }) => <Input error={errors.firstName?.message} label="Имя" {...field} />}
            />
            <Controller
              name="phone"
              rules={{
                pattern: {
                  value: /^\+7\(9[0-9]{2}\)[0-9]{3}-[0-9]{2}-[0-9]{2}/,
                  message: "Неверный формат",
                },
                required: { value: true, message: "Неверный формат" },
              }}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  maskedProps={{
                    mask: "+7(999)999-99-99",
                  }}
                  error={errors.phone?.message}
                  label="Телефон"
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: EMAIL_REG_EXP,
                  message: "Неверный формат",
                },
              }}
              defaultValue=""
              render={({ field }) => <Input error={errors.email?.message} label="Email (не обязательно)" {...field} />}
            />
          </div>
        )}
        <Controller
          name="category"
          control={control}
          defaultValue={categoryDefaultValues}
          render={({ field }) => (
            <Dropdown
              options={CATEGORIES}
              chooseOption={field.onChange}
              selectedOption={field.value}
              label="Категория обращения"
            />
          )}
        />
        <Controller
          name="text"
          rules={{
            required: { value: true, message: "Обязательно" },
            minLength: { value: 5, message: "Минимальное количество символов - 5" },
            maxLength: { value: 5000, message: "Превышен лимит символов" },
          }}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Textarea
              placeholder="Поделитесь вашим опытом — мы учтем все предложения и пожелания."
              title="Текст сообщения"
              error={errors.text?.message}
              maxLength={5000}
              rows={5}
              {...field}
            />
          )}
        />
        {enableFiles && (
          <>
            <FileInput
              name="files"
              control={control}
              maxFilesSize={10485760}
              accept="jpeg,jpg,png,gif,doc,docx,pdf,mp4,avi,mkv,mpeg,mov"
              trigger={trigger}
            />
          </>
        )}
        {agreementText && (
          <>
            <Divider />
            <Controller
              name="agreement"
              defaultValue={false}
              rules={{
                required: {
                  value: true,
                  message:
                    "Для продолжение необходимо принять условия пользовательского соглашения и дать согласие на обработку персональных данных",
                },
              }}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div className={styles.agreementWrapper}>
                  <div className={styles.agreementCheckbox}>
                    <Checkbox {...field} label="" checked={field.value} />
                    <HTMLFromRichTextEditor className={styles.markdown} htmlString={agreementText} />
                  </div>
                  {errors.agreement?.message && <Error ref={agreementRef}>{error?.message}</Error>}
                </div>
              )}
            />
          </>
        )}
      </div>
    </form>
  );
});
