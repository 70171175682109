export const MAX_MOBILE_MINI_WIDTH = 342;
export const MAX_MOBILE_WIDTH = 979;
export const MAX_LAPTOP_WIDTH = 1439;

/**
 * Return string for Next Image attribute **sizes** like:
 *
 * "*(max-width: ***342***px) ***140***px, *"
 * @param imageWidth Requested image width
 * @param deviceWidth Max device screen width
 * @returns *(max-width: ***deviceWidth***px) ***imageWidth***px,*
 */
export const getNextImageSize = (imageWidth: number, deviceWidth?: number) =>
  deviceWidth ? `(max-width: ${deviceWidth}px) ${imageWidth}px, ` : `${imageWidth}px`;

export const getImageSizes = (mobileMini: string, mobile: string, laptop: string, desktop: string) => {
  return `(max-width: 343px) ${mobileMini}, (max-width: 980px) ${mobile}, (max-width: 1440px) ${laptop}, ${desktop}`;
};
