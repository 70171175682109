import { FC, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { JumpingLoader, Toast, Modal, Button } from "@mafin/ui-kit";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { useAuth } from "@mafin/utilities";

import { toBase64 } from "@/utils/files";
import { FeedbackModalType, Form, IFormInput } from "@/components/sections/feedback/components/form";
import { Typography } from "@/components/shared/typography";
import { sendFeedback } from "@/utils/api/requests/main-site";
import { CAPTCHA } from "@/utils/constants/keys";

import styles from "./index.module.scss";

interface FeedbackModalProps {
  agreementText?: string;
  type: FeedbackModalType;
  enableFiles?: boolean;

  onClose(): void;
}

export const FeedbackModal: FC<FeedbackModalProps> = ({ agreementText, onClose, type, enableFiles = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const errorTimeout = useRef<number>();
  const { control, handleSubmit, trigger } = useForm<IFormInput>();
  const { user } = useAuth();

  const needToShowForm = !isSubmitting && !isSuccess;

  const submit: SubmitHandler<IFormInput> = async (data) => {
    const { files, ...rest } = data;
    const filesForUpload: Array<{ base64: string; fileName: string }> = [];

    if (Array.isArray(files) && files.length) {
      try {
        const convertFilePromises = files.map((i) => toBase64(i));
        const res = await Promise.all(convertFilePromises);

        res.forEach((i, index) => filesForUpload.push({ fileName: files[index].name, base64: i }));
      } catch (e) {
        console.log("cannot convert files to base64");
      }
    }

    setIsSubmitting(true);

    try {
      const draft = { ...rest, files: filesForUpload };

      if (user.profile?.phone) {
        draft.phone = user.profile.phone;
      }

      if (user.profile?.firstName) {
        draft.firstName = user.profile.firstName;
        draft.lastName = "";
      }

      const res = await sendFeedback(draft);
      const json = await res.json();

      if (json.res) {
        setIsSuccess(true);
      } else {
        setIsError(true);
      }
    } catch {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalButtonClick = () => {
    if (isSuccess) {
      onClose();
    } else {
      handleSubmit(submit)();
    }
  };

  useEffect(() => {
    if (isError) {
      errorTimeout.current = window.setTimeout(() => {
        setIsError(false);
      }, 3 * 1000);
    } else {
      clearTimeout(errorTimeout.current);
    }
  }, [isError]);

  return (
    <Modal title="Помогите нам стать лучше" shouldBeCloseBtn isOpen onClose={onClose}>
      <div className={styles.container}>
        {needToShowForm && (
          <div className={styles.formWrapper}>
            <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA}>
              <Form
                type={type}
                agreementText={agreementText}
                enableFiles={enableFiles}
                control={control}
                trigger={trigger}
              />
            </GoogleReCaptchaProvider>
          </div>
        )}
        {isSubmitting && (
          <div className={styles.loader}>
            <JumpingLoader size={64} />
          </div>
        )}
        {isSuccess && (
          <div className={styles.successMessage}>
            <img alt="success" src="/images/feedback/feedback-success.png" />
            <Typography type="simpleText">
              Служба заботы о клиентах Mafin получила ваше обращение и уже работает над ним!
            </Typography>
          </div>
        )}
        <Button className={styles.button} disabled={isSubmitting} onClick={handleModalButtonClick}>
          {isSuccess ? "Закрыть" : "Отправить"}
        </Button>
        <Toast
          isOpen={isError}
          onClose={() => setIsError(false)}
          title="Ошибка"
          value="Что-то пошло не так, попробуйте снова"
        />
      </div>
    </Modal>
  );
};
