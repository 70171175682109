import { useState, useEffect, useMemo, useCallback } from "react";

interface WindowSize {
  width?: number;
  height?: number;
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window === "undefined") return;

    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const LAPTOP_BREAKPOINT = 1439;
const MOBILE_BREAKPOINT = 979;
const MOBILE_MINI_BREAKPOINT = 342;

type Type = "laptop" | "desktop" | "mobile" | "mobileMini";

export const useBreakpoints = (): {
  type: Type;
  mobile: boolean;
  desktop: boolean;
  mobileMini: boolean;
  laptop: boolean;
  desktopOrLaptop: boolean;
  mobileOrMobileMini: boolean;
} => {
  const size = useWindowSize();
  const width = size?.width || LAPTOP_BREAKPOINT + 10;

  const getType = useCallback((): Type => {
    if (width <= MOBILE_MINI_BREAKPOINT) {
      return "mobileMini";
    } else if (width <= MOBILE_BREAKPOINT) {
      return "mobile";
    } else if (width <= LAPTOP_BREAKPOINT) {
      return "laptop";
    }

    return "desktop";
  }, [width]);

  return useMemo(() => {
    const type = getType();

    const desktop = type === "desktop";
    const laptop = type === "laptop";
    const desktopOrLaptop = type === "desktop" || type === "laptop";
    const mobile = type === "mobile";
    const mobileMini = type === "mobileMini";
    const mobileOrMobileMini = type === "mobile" || type === "mobileMini";

    return {
      type,
      desktop,
      laptop,
      mobile,
      mobileMini,
      mobileOrMobileMini,
      desktopOrLaptop,
    };
  }, [getType]);
};
